/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"



function SEO({ schemaData, description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  let schemaType = schemaData ? schemaData.type : null

  const schemaOrgJSONLD = [
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      url: "images.ctfassets.net/hteykmgi3f46/6PbEs1H0KRkQcpWZQJmHf5/3e4e6abc0b7657cc31d3f4c54c525ef7/logo__2_.png",
      name: "Cobaso",
      alternateName: 'Espresso machine and spare parts'
    }
  ];

  if (schemaType === 'product') {
    const data = schemaData ? schemaData : null
    schemaOrgJSONLD.push(
      {
        "@context": "https://schema.org/",
        "@type": "Product",
        "name": data ? data.name : "",
        "image": data ? data.photos[0].localFile.url : "",
        "description": data ? data.description.description : "",
        "brand": "Cobaso",
        "sku": "RDYKMF85",
        "mpn": "RDYKMF85",
        "offers": {
          "@type": "Offer",
          "url": "https://www.cobaso.com/product/e61-group-service-and-backflush-kit-85mm-gaskets-0e41739b-2ae6-58f3-862f-b4e663b8bc4c",
          "priceCurrency": "USD",
          "price": data ? data.priceInCents / 100 : "",
          "availability": "https://schema.org/InStock",
          "itemCondition": "https://schema.org/NewCondition"
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "bestRating": "5",
          "ratingValue": data.reviews ? 5 : 0,
          "ratingCount": data.reviews ? data.reviews.length : 0,
          "reviewCount": data.reviews ? data.reviews.length : 0
        },
        "review": [data.reviews && data.reviews.map((review) => {
          return {
            "@type": "Review",
            "name": review.customer,
            "reviewBody": review.content.content,
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": review.score,
              "bestRating": "5",
            },
            "datePublished": "2019-09-04",
            "author": { "@type": "Person", "name": "" }
          }
        })]
      }
    );
  }

  if (schemaType === 'article') {
    const data = schemaData ? schemaData : null

    schemaOrgJSONLD.push(
      {
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "https://www.cobaso.com/articles/up-close-and-personal-with-la-marzocco-strada-the-review"
        },
        "headline": data ? data.title : "",
        "description": data ? data.intro : "",
        "image": {
          "@type": "ImageObject",
          "url": data ? data.cover.localFile.url : "",
          "width": 1280,
          "height": 854
        },
        "author": {
          "@type": "Organization",
          "name": "Cobaso"
        },
        "publisher": {
          "@type": "Organization",
          "name": "Cobaso",
          "logo": {
            "@type": "ImageObject",
            "url": "images.ctfassets.net/hteykmgi3f46/6PbEs1H0KRkQcpWZQJmHf5/3e4e6abc0b7657cc31d3f4c54c525ef7/logo__2_.png",
            "width": 400,
            "height": 60
          }
        },
        "datePublished": data ? data.createdAt : "",
        "dateModified": data ? data.updatedAt : "",
      }
    );
  }
  // console.log('schemaData', schemaData)

  const metaDescription = description || site.siteMetadata.description
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {/* Schema.org tags */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
